import type { MetaMatch, MetaMatches } from "./remix.server";

export function metaMatch<
  MatchData extends Record<string, unknown>,
  RouteId extends Exclude<keyof MatchData, number | symbol>,
>(
  matches: MetaMatches<MatchData>,
  id: RouteId,
): MetaMatch<RouteId, MatchData[RouteId]> {
  const match = matches.find((match) => match.id === id);

  if (!match) {
    throw new Error(`Missing match for ${id}`);
  }

  return match as MetaMatch<RouteId, MatchData[RouteId]>;
}

export function title(...segments: string[]): string {
  return [...segments, "Cerbos Hub"].join(" | ");
}
